var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"border-radius":"10px 4px 4px 4px"},attrs:{"elevation":"2","height":_vm.height}},[_c('v-img',{staticStyle:{"border-radius":"10px 4px 4px 4px"},attrs:{"height":"100%","aspect-ratio":"1","src":_vm.animal && _vm.animal.image_id
          ? _vm.cropped_url()
          : _vm.placeholder_img}},[_c('v-overlay',{staticStyle:{"justify-content":"start","align-items":"start","pointer-events":"none"},attrs:{"opacity":"0","absolute":""}},[(_vm.animal.tag_label)?_c('v-chip',{staticStyle:{"height":"24px","margin-bottom":"-4px","margin-top":"-5px","padding-top":"2px","padding-left":"5px","padding-right":"5px","border-radius":"9px"},attrs:{"color":_vm.animal.tag_color && _vm.animal.tag_color != 'UNDEFINED'
              ? 'oc_' +
              _vm.animal.tag_color.toLowerCase() +
                ' darken-1'
              : 'grey darken-1'}},[_c('span',{staticStyle:{"color":"white","font-weight":"500","font-size":"12px"}},[_vm._v(_vm._s(_vm.animal.tag_label))])]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }