<template>
  <div>
    <v-card v-if="animal != null" min-height="568px">
      <v-card-title class="pb-0 pt-1">
        <div style="width: 100%">
          <v-tabs class="table_tabs_ac" v-model="selected_tab" icons-and-text>
            <v-tab data-cy="animalCard_tab_general"
              >General<v-icon style="display: block">mdi-cow</v-icon></v-tab
            >
            <v-tab data-cy="animalCard_tab_general"
              >Breeding<v-icon style="display: block">mdi-gender-male-female</v-icon></v-tab
            >
            <v-tab data-cy="animalCard_tab_general"
              >Pedigree<v-icon style="display: block">mdi-family-tree</v-icon></v-tab
            >
            <v-tab data-cy="animalCard_tab_general"
              >Registration<v-icon style="display: block"
                >mdi-clipboard-check</v-icon
              ></v-tab
            >
          </v-tabs>

          <span style="float: right; padding-top: 4px">
            <v-switch
              class="mr-8 mt-1"
              v-model="editable"
              label="Edit"
              color="primary"
              inset
              :disabled="!(allowed_operations == 'WRITE')"
              hide-details
              style="float: left"
              data-cy="btn_animalCard_edit"
            ></v-switch>
            <v-icon
              :disabled="animal_index_pos == 'first'"
              @click="change_animal(0)"
              class="mr-1"
              >mdi-arrow-left</v-icon
            ><v-icon
              :disabled="animal_index_pos == 'last'"
              @click="change_animal(1)"
              class="ml-1"
              >mdi-arrow-right</v-icon
            ></span
          >
        </div>
      </v-card-title>
      <v-card-text v-if="selected_tab == 0">
        <v-row>
          <v-col xl="4" lg="4" md="4" sm="6" cols="12" class="pb-1">
            <animal_card_image :animal="animal" type="head" height="248px" />
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="6" cols="12" class="pb-1">
            <animal_card_image :animal="animal" type="body" height="248px" />
          </v-col>
          <v-col xl="4" lg="4" md="4" cols="12" class="pb-1">
            <v-card
              height="100%"
              width="100%"
              min-height="195px"
              class=""
              elevation="2"
            >
              <v-card-title
                class="pb-0 pt-1"
                style="font-size: 16px"
                data-cy="text_site"
                ><v-icon color="oc_brown" class="mr-1">mdi-barn</v-icon
                >Site</v-card-title
              >
              <v-card-text class="pb-1">
                <v-row no-gutters align-content="space-between">
                  <v-col cols="12" class="hor_gutters">
                    <v-select
                      :append-icon="editable ? '$dropdown' : ''"
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      dense
                      @change="update_animal(animal.herd_id, 'animal.herd_id')"
                      v-model="animal.herd_id"
                      :items="herds[0]"
                      label="Herd"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="hor_gutters">
                    <v-select
                      :append-icon="editable ? '$dropdown' : ''"
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Ownership"
                      dense
                      @change="
                        update_animal(
                          animal.ownership_status,
                          'animal.ownership_status'
                        )
                      "
                      :items="enums.ownership_status"
                      v-model="animal.ownership_status"
                    />
                  </v-col>
                  <v-col cols="12" class="hor_gutters">
                    <v-menu
                      nudge-top="9"
                      nudge-right="12"
                      :close-on-content-click="false"
                      open-on-click
                      left
                      offset-overflow
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          hide-details
                          outlined
                          readonly
                          :style="!editable ? 'pointer-events: none' : ''"
                          label="Date of Arrival"
                          dense
                          v-model="animal.site_doa"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:append>
                            <v-icon data-cy="btn_calender_dateOfArrival"
                              >mdi-calendar</v-icon
                            ></template
                          >
                        </v-text-field>
                      </template>

                      <v-card v-if="editable">
                        <v-row>
                          <v-col>
                            <v-date-picker
                              @change="
                                update_animal(
                                  animal.site_doa,
                                  'animal.site_doa'
                                )
                              "
                              v-model="animal.site_doa"
                              flat
                              no-title
                              event-color="#ff9900"
                            ></v-date-picker>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="hor_gutters">
                    <v-select
                      :append-icon="editable ? '$dropdown' : ''"
                      outlined
                      hide-details
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Purpose"
                      dense
                      @change="update_animal(animal.purpose, 'animal.purpose')"
                      :items="[
                        { value: 'BREEDING', text: 'Breeding' },
                        { value: 'DAIRY', text: 'Dairy' },
                        { value: 'BEEF', text: 'Beef' },
                        { value: 'OTHER', text: 'Other' },
                      ]"
                      v-model="animal.purpose"
                    />
                  </v-col>
                </v-row> </v-card-text
            ></v-card>
          </v-col>
          <v-col cols="12" class="pb-1">
            <v-card height="100%" width="100%" class="mr-3" elevation="2">
              <v-card-title class="pb-0 pt-1" style="font-size: 16px"
                ><v-icon color="oc_blue" class="mr-1">mdi-information</v-icon
                >Information</v-card-title
              >
              <v-card-text class="pb-1">
                <v-row no-gutters align-content="space-between">
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="6"
                    class="hor_gutters"
                  >
                    <v-combobox
                      hide-details
                      outlined
                      readonly
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Tag ID"
                      dense
                      append-icon=""
                      v-model="animal.tag_label"
                      chips
                    >
                      <template v-slot:prepend-inner
                        ><v-menu
                          nudge-top="9"
                          nudge-right="12"
                          :close-on-content-click="false"
                          open-on-click
                          left
                          offset-overflow
                          v-model="tag_menu"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              style="
                                width: calc(100% - 20px);
                                height: 30px;
                                position: absolute;
                                z-index: 9;
                              "
                            ></div>
                          </template>

                          <v-card width="240px" v-if="editable">
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" class="pt-0">
                                  <v-text-field
                                    hide-details
                                    outlined
                                    label="Name"
                                    dense
                                    v-model="animal.tag_label"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    hide-details
                                    outlined
                                    label="Color"
                                    dense
                                    :items="enums.tag_color"
                                    hide-no-data
                                    v-model="animal.tag_color"
                                  />
                                </v-col>

                                <v-col align-self="end" class="pb-0 pt-0">
                                  <v-btn
                                    @click="
                                      update_animal(
                                        {
                                          label: animal.tag_label,
                                          color: animal.tag_color,
                                          id: animal.tag_id,
                                        },
                                        'tag'
                                      )
                                    "
                                    style="float: right"
                                    color="primary"
                                    text
                                    >Save</v-btn
                                  >
                                  <v-btn
                                    @click="tag_menu = false"
                                    style="float: right"
                                    color="primary"
                                    text
                                    >Cancel</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-menu></template
                      >
                      <template v-slot:selection="{ attrs }">
                        <v-chip
                          style="
                            color: white;
                            font-weight: 500;
                            margin-bottom: -3px;
                          "
                          small
                          v-bind="attrs"
                          :color="
                            animal.tag_color
                              ? 'oc_' +
                                animal.tag_color.toLowerCase() +
                                ' darken-1'
                              : 'grey darken-1'
                          "
                        >
                          {{ animal.tag_label }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="6"
                    class="hor_gutters"
                  >
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="RFID"
                      dense
                      @change="
                        update_animal(
                          animal.rfid_current,
                          'animal.rfid_current'
                        )
                      "
                      v-model="animal.rfid_current"
                    />
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="6"
                    class="hor_gutters"
                  >
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Name"
                      dense
                      v-model="animal.name"
                      @change="update_animal(animal.name, 'animal.name')"
                    />
                  </v-col>

                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="6"
                    class="hor_gutters"
                  >
                    <v-select
                      hide-details
                      outlined
                      :append-icon="editable ? '$dropdown' : ''"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Production Type"
                      dense
                      @change="
                        update_animal(
                          animal.production_history_type,
                          'production_history.type'
                        )
                      "
                      :items="enums.production_type"
                      v-model="animal.production_history_type"
                    />
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="6"
                    class="hor_gutters"
                  >
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Breed"
                      dense
                      @change="update_animal(animal.breed, 'animal.breed')"
                      v-model="animal.breed"
                    />
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="6"
                    class="hor_gutters"
                  >
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Color"
                      dense
                      @change="update_animal(animal.color, 'animal.color')"
                      v-model="animal.color"
                    />
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="6"
                    class="hor_gutters"
                    style="height: 52px"
                  >
                    <v-select
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :append-icon="editable ? '$dropdown' : ''"
                      label="Sex"
                      dense
                      @change="update_animal(animal.sex, 'animal.sex')"
                      :items="[
                        { value: 'MALE', text: 'Male' },
                        { value: 'FEMALE', text: 'Female' },
                      ]"
                      v-model="animal.sex"
                    />
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="6"
                    class="hor_gutters"
                    style="height: 52px"
                  >
                    <v-select
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :append-icon="editable ? '$dropdown' : ''"
                      label="Classification"
                      dense
                      @change="
                        update_animal(
                          animal.classification,
                          'animal.classification'
                        )
                      "
                      hide-selected
                      :items="enums.sex_classification"
                      v-model="animal.classification"
                    />
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    cols="6"
                    class="hor_gutters"
                    style="height: 52px"
                  >
                    <v-select
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :append-icon="editable ? '$dropdown' : ''"
                      label="Status"
                      dense
                      @change="update_animal(animal.status, 'animal.status')"
                      :items="enums.animal_status"
                      v-model="animal.status"
                    />
                  </v-col>
                </v-row> </v-card-text
            ></v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="selected_tab == 1">
        <v-row>
          <v-col xl="4" lg="4" md="4" sm="6" cols="12" class="pb-1">
            <animal_card_image :animal="animal" type="head" height="248px" />
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="6" cols="12" class="pb-1">
            <animal_card_image :animal="animal" type="body" height="248px" />
          </v-col>

          <v-col xl="4" lg="4" md="4" cols="12" class="pb-1">
            <v-card height="100%" width="100%" class="" elevation="2">
              <v-card-title class="pb-0 pt-1" style="font-size: 16px"
                ><v-icon color="oc_purple" class="mr-1" >mdi-calendar</v-icon
                >Lineage</v-card-title
              >
              <v-card-text class="pb-1">
                <v-row no-gutters align-content="space-between">
                  <v-col cols="12" class="hor_gutters">
                    <v-menu
                      nudge-top="9"
                      nudge-right="12"
                      :close-on-content-click="false"
                      open-on-click
                      left
                      offset-overflow
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          readonly
                          :style="!editable ? 'pointer-events: none' : ''"
                          hide-details
                          label="Date of Birth"
                          dense
                          v-model="animal.dob"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:append>
                            <v-icon data-cy="btn_calender_dob"
                              >mdi-calendar</v-icon
                            ></template
                          >
                        </v-text-field>
                      </template>

                      <v-card v-if="editable">
                        <v-row>
                          <v-col>
                            <v-date-picker
                              hide-details
                              @change="update_animal(animal.dob, 'animal.dob')"
                              v-model="animal.dob"
                              flat
                              no-title
                              event-color="#ff9900"
                            ></v-date-picker>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" class="hor_gutters">
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :label="
                        unit_system_multiplier
                          ? 'Birth Weight (lbs)'
                          : 'Birth Weight (kg)'
                      "
                      dense
                      @change="
                        update_animal(
                          animal.measurement_weight_birth,
                          'measurement.WEIGHT_BIRTH'
                        )
                      "
                      v-model="animal.measurement_weight_birth"
                    />
                  </v-col>
                  <v-col cols="12" class="hor_gutters">
                    <v-select
                      :append-icon="editable ? '$dropdown' : ''"
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      dense
                      @change="
                        update_animal(animal.nurse_info, 'animal.nurse_info')
                      "
                      v-model="animal.nurse_info"
                      :items="enums.nurse_info"
                      label="Nurse Info"
                    ></v-select>
                  </v-col>
                </v-row> </v-card-text
            ></v-card>
          </v-col>
          <v-col cols="12" class="pb-1">
            <v-card height="100%" width="100%" class="" elevation="2">
              <v-card-title
                class="pb-0 pt-1"
                style="font-size: 16px"
                data-cy="text_breeding"
                ><v-icon color="oc_pink" class="mr-1" v-if="animal.sex == 'FEMALE'">mdi-gender-female</v-icon
                ><v-icon color="oc_blue" class="mr-1" v-else>mdi-gender-male</v-icon
                >Breeding</v-card-title
              >
              <v-card-text class="pb-1">
                <v-row
                  no-gutters
                  align-content="space-between"
                  v-if="animal.sex == 'FEMALE'"
                >
                  <v-col cols="6" class="hor_gutters">
                    <v-select
                      :append-icon="editable ? '$dropdown' : ''"
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Dam Status"
                      dense
                      @change="
                        update_animal(animal.dam_status, 'animal.dam_status')
                      "
                      :items="enums.dam_status"
                      v-model="animal.dam_status"
                    />
                  </v-col>
                  <v-col cols="6" class="hor_gutters">
                    <v-select
                      :append-icon="editable ? '$dropdown' : ''"
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Pregnant"
                      dense
                      @change="
                        update_animal(
                          animal.breeding_history_pregnant,
                          'breeding_history.pregnant'
                        )
                      "
                      :items="[
                        { value: true, text: 'Yes' },
                        { value: false, text: 'No' },
                      ]"
                      v-model="animal.breeding_history_pregnant"
                    />
                  </v-col>
                  <v-col cols="6" class="hor_gutters">
                    <v-text-field
                      hide-details
                      outlined
                      readonly
                      label="Due Date"
                      dense
                      v-model="animal.breeding_history_end_date"
                    >
                      <template v-slot:append
                        ><v-menu
                          nudge-top="9"
                          nudge-right="12"
                          :close-on-content-click="false"
                          open-on-click
                          left
                          offset-overflow
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              data-cy="btn_calender_breedingDate"
                              >mdi-calendar</v-icon
                            >
                          </template>

                          <v-card v-if="editable">
                            <v-row>
                              <v-col>
                                <v-date-picker
                                  hide-details
                                  @change="
                                    update_animal(
                                      animal.breeding_history_end_date,
                                      'breeding_history.end_date'
                                    )
                                  "
                                  v-model="animal.breeding_history_end_date"
                                  flat
                                  no-title
                                  event-color="#ff9900"
                                ></v-date-picker>
                              </v-col>
                            </v-row>
                          </v-card> </v-menu
                      ></template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="6" class="hor_gutters">
                    <v-select
                      :append-icon="editable ? '$dropdown' : ''"
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      dense
                      @change="
                        update_animal(
                          animal.breeding_history_type,
                          'breeding_history.type'
                        )
                      "
                      v-model="animal.breeding_history_type"
                      :items="[
                        {
                          value: 'ARTIFICIAL_INSEMINATION',
                          text: 'Artificial Insemination',
                        },
                        { value: 'ET_DONOR', text: 'Embry Transfer Donor' },

                        {
                          value: 'ET_RECIPIENT',
                          text: 'Embry Transfer Recipient',
                        },
                        {
                          value: 'NATURAL',
                          text: 'Natural',
                        },
                        { value: 'UNDEFINED', text: 'Undefined' },
                      ]"
                      label="Breeding Type"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" class="hor_gutters">
                    <v-text-field
                      hide-details
                      outlined
                      readonly
                      label="Breeding Date"
                      dense
                      v-model="animal.breeding_history_start_date"
                    >
                      <template v-slot:append
                        ><v-menu
                          nudge-top="9"
                          nudge-right="12"
                          :close-on-content-click="false"
                          open-on-click
                          left
                          offset-overflow
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              data-cy="btn_calender_breedingDate"
                              >mdi-calendar</v-icon
                            >
                          </template>

                          <v-card v-if="editable">
                            <v-row>
                              <v-col>
                                <v-date-picker
                                  hide-details
                                  @change="
                                    update_animal(
                                      animal.breeding_history_start_date,
                                      'breeding_history.start_date'
                                    )
                                  "
                                  v-model="animal.breeding_history_start_date"
                                  flat
                                  no-title
                                  event-color="#ff9900"
                                ></v-date-picker>
                              </v-col>
                            </v-row>
                          </v-card> </v-menu
                      ></template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters align-content="space-between" v-else>
                  <v-col cols="6" class="hor_gutters"
                    ><v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :label="
                        unit_system_multiplier
                          ? 'Pelvic Height (in)'
                          : 'Pelvic Height (cm)'
                      "
                      dense
                      @change="
                        update_animal(
                          animal.measurement_pelvic_height,
                          'measurement.PELVIC_HEIGHT'
                        )
                      "
                      v-model="animal.measurement_pelvic_height"
                  /></v-col>
                  <v-col cols="6" class="hor_gutters"
                    ><v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :label="
                        unit_system_multiplier
                          ? 'Pelvic Width (in)'
                          : 'Pelvic Width (cm)'
                      "
                      dense
                      @change="
                        update_animal(
                          animal.measurement_pelvic_width,
                          'measurement.PELVIC_WIDTH'
                        )
                      "
                      v-model="animal.measurement_pelvic_width"
                  /></v-col>
                  <v-col cols="6" class="hor_gutters"
                    ><v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :label="
                        unit_system_multiplier
                          ? 'Scrotum Circumference (in)'
                          : 'Scrotum Circumference (cm)'
                      "
                      dense
                      @change="
                        update_animal(
                          animal.measurement_scrotum_circumference,
                          'measurement.SCROTUM_CIRCUMFERENCE'
                        )
                      "
                      v-model="animal.measurement_scrotum_circumference"
                  /></v-col>
                  <v-col cols="6" class="hor_gutters"
                    ><v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :label="'Sperm Morphology (%)'"
                      dense
                      @change="
                        update_animal(
                          animal.measurement_sperm_morphology,
                          'measurement.SPERM_MORPHOLOGY'
                        )
                      "
                      v-model="animal.measurement_sperm_morphology"
                  /></v-col>
                  <v-col cols="6" class="hor_gutters"
                    ><v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :label="'Sperm Motility (%)'"
                      dense
                      @change="
                        update_animal(
                          animal.measurement_sperm_motility,
                          'measurement.SPERM_MOTILITY'
                        )
                      "
                      v-model="animal.measurement_sperm_motility"
                  /></v-col>
                </v-row> </v-card-text
            ></v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="selected_tab == 2">
        <v-row>
          <v-col xl="5" lg="5" md="5" sm="12" cols="12" class="pb-1">
            <animal_card_image :animal="animal" type="head" height="248px" />
          </v-col>

          <v-col xl="7" lg="5" md="5" cols="12" class="pb-1">
            <v-card
              height="100%"
              width="100%"
              min-height="248px"
              class=""
              elevation="2"
            >
              <!-- <v-card-title
                class="pb-0 pt-1"
                style="font-size: 16px"
                data-cy="text_site"
                ><v-icon color="oc_green" class="mr-1">mdi-family-tree</v-icon
                >Pedigree</v-card-title
              > -->
              <v-card-text class="pb-0 pl-0" style="padding-top: 2px">
                <v-row no-gutters align-content="space-around">
                  <vue-tree
                    v-if="family_tree"
                    :direction="'vertical'"
                    style="width: 100%; height: 235px"
                    :dataset="family_tree"
                    :config="treeConfig"
                    linkStyle="straight"
                  >
                    <template v-slot:node="{ node }">
                      <div style="width: 60px">
                        <animal_card_image
                          height="60px"
                          :animal="node.value"
                          type="head"
                          :ancestor="true"
                        />
                      </div>
                    </template>
                  </vue-tree>
                </v-row> </v-card-text
            ></v-card>
          </v-col>

          <v-col cols="12" class="pb-1">
            <v-card
              height="100%"
              width="100%"
              min-height="196px"
              max-height="196px"
              class="pt-1"
              elevation="2"
            >
              <!-- <v-card-title
                class="pb-0 pt-1"
                style="font-size: 16px"
                data-cy="text_site"
                ><v-icon color="oc_orange" class="mr-1">mdi-cow</v-icon
                >Offspring</v-card-title
              > -->
              <v-card-text class="pb-1 pt-0">
                <v-list-item style="height: 100%" dense>
                  <v-list-item-content v-if="offspring">
                    <v-data-table
                      style="height: 176px; overflow-y: scroll"
                      :headers="offspring_headers"
                      :items="offspring"
                      :items-per-page="100"
                      class="elevation-0"
                      hide-default-footer
                      dense
                      tit
                    >
                      <template v-slot:[`item.tag_label`]="{ item }">
                        <v-img
                          height="76px"
                          class="ma-1"
                          style="border-radius: 12px"
                          aspect-ratio="1"
                          :src="
                            item.image_id
                              ? cropped_url(
                                  item.image_id,
                                  item.image_timestamp,
                                  item.customer_id,
                                  'head'
                                )
                              : placeholder_img
                          "
                        >
                          <v-overlay
                            opacity="0"
                            absolute
                            style="
                              justify-content: start;
                              align-items: start;
                              pointer-events: none;
                            "
                          >
                            <v-chip
                              style="
                                height: 24px;
                                margin-bottom: -4px;
                                margin-top: -5px;
                                padding-top: 2px;
                                padding-left: 5px;
                                padding-right: 5px;
                                border-radius: 9px;
                              "
                              v-if="item.tag_label"
                              :color="
                                item.tag_color && item.tag_color != 'UNDEFINED'
                                  ? 'oc_' +
                                    item.tag_color.toLowerCase() +
                                    ' darken-1'
                                  : 'grey darken-1'
                              "
                            >
                              <span
                                style="
                                  color: white;
                                  font-weight: 500;
                                  font-size: 12px;
                                "
                                >{{ item.tag_label }}</span
                              ></v-chip
                            >
                          </v-overlay>
                        </v-img>
                      </template>
                      <template v-slot:[`item.birth_ease`]="{ item }">
                        <span>{{
                          capitalizeFirstLetter(item.birth_ease)
                        }}</span>
                      </template>
                      <template v-slot:[`item.classification`]="{ item }">
                        <span>{{
                          capitalizeFirstLetter(item.classification)
                        }}</span>
                      </template>
                    </v-data-table>
                  </v-list-item-content>
                </v-list-item>
              </v-card-text></v-card
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="selected_tab == 3">
        <v-row>
          <v-col xl="4" lg="4" md="4" sm="6" cols="12" class="pb-1">
            <animal_card_image :animal="animal" type="head" height="248px" />
          </v-col>
          <v-col xl="4" lg="4" md="4" sm="6" cols="12" class="pb-1">
            <animal_card_image :animal="animal" type="body" height="248px" />
          </v-col>

          <v-col xl="4" lg="4" md="4" sm="12" cols="12" class="pb-1">
            <v-card height="100%" width="100%" class="mr-3" elevation="2">
              <v-card-title class="pb-0 pt-1" style="font-size: 16px"
                ><v-icon color="oc_blue" class="mr-1">mdi-information</v-icon
                >Information</v-card-title
              >
              <v-card-text class="pb-1">
                <v-row no-gutters align-content="space-between">
                  <v-col cols="12" class="hor_gutters">
                    <v-combobox
                      hide-details
                      outlined
                      readonly
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Tag ID"
                      dense
                      append-icon=""
                      v-model="animal.tag_label"
                      chips
                    >
                      <template v-slot:prepend-inner
                        ><v-menu
                          nudge-top="9"
                          nudge-right="12"
                          :close-on-content-click="false"
                          open-on-click
                          left
                          offset-overflow
                          v-model="tag_menu"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              style="
                                width: calc(100% - 20px);
                                height: 30px;
                                position: absolute;
                                z-index: 9;
                              "
                            ></div>
                          </template>

                          <v-card width="240px" v-if="editable">
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" class="pt-0">
                                  <v-text-field
                                    hide-details
                                    outlined
                                    label="Name"
                                    dense
                                    v-model="animal.tag_label"
                                  />
                                </v-col>
                                <v-col cols="12">
                                  <v-autocomplete
                                    hide-details
                                    outlined
                                    label="Color"
                                    dense
                                    :items="enums.tag_color"
                                    hide-no-data
                                    v-model="animal.tag_color"
                                  />
                                </v-col>

                                <v-col align-self="end" class="pb-0 pt-0">
                                  <v-btn
                                    @click="
                                      update_animal(
                                        {
                                          label: animal.tag_label,
                                          color: animal.tag_color,
                                          id: animal.tag_id,
                                        },
                                        'tag'
                                      )
                                    "
                                    style="float: right"
                                    color="primary"
                                    text
                                    >Save</v-btn
                                  >
                                  <v-btn
                                    @click="tag_menu = false"
                                    style="float: right"
                                    color="primary"
                                    text
                                    >Cancel</v-btn
                                  >
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-menu></template
                      >
                      <template v-slot:selection="{ attrs }">
                        <v-chip
                          style="
                            color: white;
                            font-weight: 500;
                            margin-bottom: -3px;
                          "
                          small
                          v-bind="attrs"
                          :color="
                            animal.tag_color
                              ? 'oc_' +
                                animal.tag_color.toLowerCase() +
                                ' darken-1'
                              : 'grey darken-1'
                          "
                        >
                          {{ animal.tag_label }}
                        </v-chip>
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="12" class="hor_gutters">
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="RFID"
                      dense
                      @change="
                        update_animal(
                          animal.rfid_current,
                          'animal.rfid_current'
                        )
                      "
                      v-model="animal.rfid_current"
                    />
                  </v-col>
                  <v-col cols="12" class="hor_gutters">
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Name"
                      dense
                      v-model="animal.name"
                      @change="update_animal(animal.name, 'animal.name')"
                    />
                  </v-col>
                  <v-col cols="12" class="hor_gutters" style="height: 52px">
                    <v-select
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      :append-icon="editable ? '$dropdown' : ''"
                      label="Classification"
                      dense
                      @change="
                        update_animal(
                          animal.classification,
                          'animal.classification'
                        )
                      "
                      hide-selected
                      :items="enums.sex_classification"
                      v-model="animal.classification"
                    />
                  </v-col>
                </v-row> </v-card-text
            ></v-card>
          </v-col>


          <v-col cols="12" class="pb-1">
            <v-card
              height="100%"
              width="100%"
              min-height="195px"
              class=""
              elevation="2"
            >
              <v-card-title
                class="pb-0 pt-1"
                style="font-size: 16px"
                data-cy="text_site"
                ><v-icon color="oc_yellow" class="mr-1"
                  >mdi-list-box-outline</v-icon
                >Registration</v-card-title
              >
              <v-card-text class="pb-1">
                <v-row no-gutters align-content="space-between">
                  <v-col cols="6" class="hor_gutters">
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Registered Name"
                      dense
                      @change="
                        update_animal(
                          pedigree.registered_name,
                          'pedigree.registered_name'
                        )
                      "
                      v-model="pedigree.registered_name"
                    />
                  </v-col>
                  <v-col cols="6" class="hor_gutters">
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Registered Number"
                      dense
                      @change="
                        update_animal(
                          pedigree.registration_number,
                          'pedigree.registration_number'
                        )
                      "
                      v-model="pedigree.registration_number"
                    />
                  </v-col>
                  <v-col cols="6" class="hor_gutters">
                    <v-text-field
                      hide-details
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Tattoo"
                      dense
                      @change="
                        update_animal(pedigree.tattoo, 'pedigree.tattoo')
                      "
                      v-model="pedigree.tattoo"
                    />
                  </v-col>
                  <v-col cols="6" class="hor_gutters">
                    <v-autocomplete
                      hide-details
                      @change="
                        update_animal(
                          pedigree.registration_association,
                          'pedigree.registration_association'
                        )
                      "
                      :items="[
                        'Canadian Angus Association',
                        'Canadian Belgian Blue Association',
                        'Canadian Blonde dAquitaine Association',
                        'Canadian Charolais Association',
                        'Canadian Galloway Association',
                        'Canadian Gelbvieh Association',
                        'Canadian Hereford Association',
                        'Canadian Highland Cattle Society',
                        'Canadian Limousin Association',
                        'Canadian Lowline Cattle Association',
                        'Canadian Maine-Anjou Association',
                        'Canadian Murray Grey Association',
                        'Salers Association of Canada',
                        'Canadian Shorthorn Association',
                        'Canadian Simmental Association',
                        'Canadian Speckle Park Association',
                      ]"
                      outlined
                      :readonly="!editable"
                      :style="!editable ? 'pointer-events: none' : ''"
                      label="Association"
                      dense
                      v-model="pedigree.registration_association"
                    />
                  </v-col>

                  <v-col cols="6" class="hor_gutters">
                    <v-menu
                      nudge-top="9"
                      nudge-right="12"
                      :close-on-content-click="false"
                      open-on-click
                      left
                      offset-overflow
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          hide-details
                          outlined
                          readonly
                          :style="!editable ? 'pointer-events: none' : ''"
                          label="Registration Date"
                          dense
                          v-model="pedigree.registration_date"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <template v-slot:append>
                            <v-icon data-cy="btn_calender_dateOfArrival"
                              >mdi-calendar</v-icon
                            ></template
                          >
                        </v-text-field>
                      </template>

                      <v-card v-if="editable">
                        <v-row>
                          <v-col>
                            <v-date-picker
                              @change="
                                update_animal(
                                  pedigree.registration_date,
                                  'pedigree.registration_date'
                                )
                              "
                              v-model="pedigree.registration_date"
                              flat
                              no-title
                              event-color="#ff9900"
                            ></v-date-picker>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-menu>
                  </v-col>
                </v-row> </v-card-text
            ></v-card>
          </v-col>


  
  
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { validateDate, validateIsNumber } from "@/utilities/ValidationRules";
import moment from "moment";
import { eventBus } from "../main.js";
import animal_card_image from "./animal_card_image.vue";
import axios from "axios";
import * as hasura_mutations from "../graphql_hasura/mutations";
import { v4 as uuidv4 } from "uuid";
import VueTree from "@ssthouse/vue-tree-chart";
import placeholder_img from "@/assets/cover_brown.png";
import placeholder_img_dam from "@/assets/cover_brown.png";
import placeholder_img_sire from "@/assets/cover_brown.png";

export default {
  name: "animalCard",
  components: {
    animal_card_image,
    VueTree,
  },
  props: ["animal_prop", "animal_index_pos", "tab_index", "index"],
  data: () => ({
    placeholder_img: placeholder_img,
    placeholder_img_dam: placeholder_img_dam,
    placeholder_img_sire: placeholder_img_sire,

    tag_menu: false,
    identity_id: null,
    s3_client: null,
    animal_image: null,
    selected_tab: 0,

    image_error: "",
    image_select: 0,
    editable: false,
    placeholder: "",
    image_select_sire: 0,
    image_select_dam: 0,

    edit_value_note: null,
    note_dialog: false,
    note_text: "",

    animal: {},
    pedigree: {},

    treeConfig: {
      nodeWidth: 66,
      nodeHeight: 60,
      levelHeight: 80,
    },

    family_tree: false,
  }),

  methods: {
    validateDate,
    validateIsNumber,

    capitalizeFirstLetter(string) {
      if (string) {
        if (
          string == "UNDEFINED" ||
          string == "Undefined" ||
          !string ||
          string == ""
        ) {
          return "-";
        }
        return (string.charAt(0) + string.slice(1).toLowerCase()).replaceAll(
          "_",
          " "
        );
      }
      return "-";
    },

    cropped_url(id, timestamp, customer_id, type) {
      let ymd = moment(timestamp).format("YYYY/MM/DD");
      let total_date = moment(timestamp).format("YYYY-MM-DD_HH-mm-ss");
      return `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${customer_id}/animals/images/${ymd}/${id}_${total_date}_${type}.jpg`;
    },

    select_animal(animal) {
      eventBus.$emit("change_animal", animal);
    },

    async update_animal(value, field) {
      try {
        var temp_animal = this.animal_prop;
        var set_params = {};
        if (field == "tag") {
          set_params = {
            label: value.label,
            color: value.color,
          };

          if (value.id) {
            set_params = {
              label: value.label,
              color: value.color,
            };
            let response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.update_tag_by_pk,
                variables: {
                  pk_columns: { id: value.id },
                  _set: set_params,
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (response.errors || response.data.errors) {
              throw new Error("GraphQL error: " + response.errors[0].message);
            }
            temp_animal.tag_label = value.label;
            temp_animal.tag_color = value.color;
          } else {
            set_params = {
              label: value.label,
              color: value.color,
              customer_id: this.animal.customer_id,
              id: uuidv4(),
              date: moment().format("YYYY-MM-DDTHH:mm:ss"),
              animal_id: this.animal.id,
            };

            let response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.insert_tag_one,
                variables: {
                  object1: set_params,
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (response.errors || response.data.errors) {
              throw new Error("GraphQL error: " + response.errors[0].message);
            }
            let animal_response = await axios.post(
              this.$store.getters.getHasuraParamsWrite.url,
              {
                query: hasura_mutations.update_animal_by_pk,
                variables: {
                  pk_columns: { id: this.animal.id },
                  _set: { current_tag_id: set_params.id },
                },
              },
              {
                headers: this.$store.getters.getHasuraParamsWrite.headers,
              }
            );
            if (animal_response.errors || animal_response.data.errors) {
              throw new Error("GraphQL error: ");
            }
            temp_animal.tag_label = value.label;
            temp_animal.tag_color = value.color;
            temp_animal.tag_id = value.id;
            temp_animal.tag_date = set_params.date;
          }
        } else if (field.includes("animal")) {
          let set_field = field.split(".")[1];
          set_params[set_field] = value;
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.update_animal_by_pk,
              variables: {
                pk_columns: { id: this.animal.id },
                _set: set_params,
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          temp_animal[set_field] = value;
          if (set_field == "herd_id") {
            temp_animal.herd_name = this.herds[1][value];
          }
        } else if (field.includes("production_history")) {
          set_params.start_datetime = this.animal
            .production_history_start_datetime
            ? this.animal.production_history_start_datetime
            : moment().format("YYYY-MM-DDThh:mm:ss");
          set_params.type = value;
          set_params.animal_id = this.animal.id;
          set_params.customer_id = this.animal.customer_id;
          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_production_history_one,
              variables: {
                object1: set_params,
                update_columns: ["type"],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          temp_animal.production_history_type = value;
        } else if (field.includes("breeding_history")) {
          let set_field = field.split(".")[1];

          if (this.animal.breeding_history_id) {
            set_params.id = this.animal.breeding_history_id;
            set_params.type = this.animal.breeding_history_type;
            set_params.start_date = this.animal.breeding_history_start_date;
          } else {
            set_params.id = uuidv4();
            set_params.type = "UNDEFINED";
            set_params.start_date = moment().format("YYYY-MM-DD");
          }
          set_params.animal_id = this.animal.id;
          set_params.customer_id = this.animal.customer_id;
          set_params[set_field] = value;

          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_breeding_history_one,
              variables: {
                object1: set_params,
                update_columns: [set_field],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          if (!this.animal.breeding_history_id) {
            temp_animal.id = set_params.id;
            temp_animal.type = set_params.type;
            temp_animal.start_date = set_params.start_date;
          }
          temp_animal[`breeding_history_${set_field}`] = value;
        } else if (field.includes("measurement")) {
          let set_field = field.split(".")[1];
          console.log(set_field);
          if (this.animal[`measurement_${set_field.toLowerCase()}_id`]) {
            set_params.id =
              this.animal[`measurement_${set_field.toLowerCase()}_id`];
          } else {
            set_params.id = uuidv4();
          }
          set_params.type = set_field;
          set_params.animal_id = this.animal.id;
          set_params.customer_id = this.animal.customer_id;
          set_params.examiner = this.animal.customer_id;
          if (set_field.includes("WIDTH")) {
            set_params.value = this.unit_system_multiplier
              ? value / this.unit_system_multiplier.weight
              : value;
            set_params.unit = "KG";
          } else if (set_field.includes("HEIGHT")) {
            set_params.value = this.unit_system_multiplier
              ? value / this.unit_system_multiplier.length
              : value;
            set_params.unit = "CM";
          } else {
            set_params.value = value;
            set_params.unit = "PERCENT";
          }

          set_params.date = moment().format("YYYY-MM-DDThh:mm:ss");

          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_measurement_one,
              variables: {
                object1: set_params,
                update_columns: ["value"],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          temp_animal[`measurement_${set_field.toLowerCase()}_id`] =
            set_params.id;
          temp_animal[`measurement_${set_field.toLowerCase()}`] = value;
        } else if (field.includes("pedigree")) {
          let set_field = field.split(".")[1];

          if (!this.animal.pedigree || !this.animal.pedigree.registered_name) {
            set_params.tattoo = "-";
            set_params.registered_name = "-";
            set_params.registration_association = "-";
            set_params.registration_number = "-";
            set_params.registration_date = moment().format("YYYY-MM-DD");
          } else {
            set_params = { ...this.animal.pedigree };
          }
          set_params.animal_id = this.animal.id;
          set_params.customer_id = this.animal.customer_id;

          set_params[set_field] = value;

          let response = await axios.post(
            this.$store.getters.getHasuraParamsWrite.url,
            {
              query: hasura_mutations.insert_pedigree_one,
              variables: {
                object1: set_params,
                update_columns: [set_field],
              },
            },
            {
              headers: this.$store.getters.getHasuraParamsWrite.headers,
            }
          );
          console.log(set_field, value, response);
          if (response.errors || response.data.errors) {
            throw new Error("GraphQL error: " + response.errors[0].message);
          }
          temp_animal.pedigree = {
            tattoo: set_params.tattoo,
            registered_name: set_params.registered_name,
            registration_association: set_params.registration_association,
            registration_number: set_params.registration_number,
            registration_date: moment().format("YYYY-MM-DD"),
          };

          temp_animal.pedigree[set_field] = value;
        }

        this.tag_menu = false;
        eventBus.$emit("add_button_alert", {
          msg: "Successfully updated the animal",
          err: false,
        });
      } catch (err) {
        this.animal = { ...this.animal_prop };
        if (this.unit_system_multiplier) {
          this.animal.measurement_weight_birth =
            this.animal.measurement_weight_birth *
            this.unit_system_multiplier.weight;
        }
        if (this.animal_prop.pedigree) {
          this.pedigree = { ...this.animal_prop.pedigree };
        }
        eventBus.$emit("add_button_alert", {
          msg: "Error updating the animal",
          err: true,
        });
      }
    },

    change_animal(dir) {
      eventBus.$emit("change_animal", dir);
    },

    activate_upload() {
      // Trigger click on the FileInput
      this.$refs.uploader_image.click();
    },
    on_file_change_image(e) {
      this.animal_image = e.target.files[0];
    },

    age(dob) {
      var months = moment().diff(dob, "months");
      var years = Math.floor(months / 12);
      if (years >= 1) {
        return years + " yr. " + (months % 12) + " mth.";
      }
      return (months % 12) + " mth.";
    },
  },
  async mounted() {
    if (this.animal_prop) {
      this.$store
        .dispatch("DDB_SELECTED_ANIMAL_PARENTS", {
          dam_id: this.animal_prop.dam_id,
          sire_id: this.animal_prop.sire_id,
        })
        .then(() => {
          var temp_family_tree_data = {
            value: this.animal_prop,
            children: [
              {
                value: { tag_label: "???", type: "dam" },
                children: [
                  { value: { tag_label: "???", type: "dam" } },
                  { value: { tag_label: "???", type: "sire" } },
                ],
              },
              {
                value: { tag_label: "???", type: "sire" },
                children: [
                  { value: { tag_label: "???", type: "dam" } },
                  { value: { tag_label: "???", type: "sire" } },
                ],
              },
            ],
          };
          if (this.animal_prop.dam_id) {
            temp_family_tree_data = {
              value: this.animal_prop,
              children: [
                {
                  value: {
                    tag_label: this.animal_prop.dam_tag_label,
                    tag_color: this.animal_prop.dam_tag_color,
                    image_timestamp: this.animal_prop.dam_image_timestamp,
                    image_id: this.animal_prop.dam_image_id,
                    id: this.animal_prop.dam_id,
                    customer_id: this.animal_prop.customer_id,
                    type: "dam",
                  },
                  children: [
                    { value: { tag_label: "???", type: "dam" } },
                    { value: { tag_label: "???", type: "sire" } },
                  ],
                },
                {
                  value: {
                    tag_label: this.animal_prop.sire_tag_label,
                    tag_color: this.animal_prop.sire_tag_color,
                    image_timestamp: this.animal_prop.sire_image_timestamp,
                    image_id: this.animal_prop.sire_image_id,
                    id: this.animal_prop.dam_id,
                    customer_id: this.animal_prop.customer_id,
                    type: "sire",
                  },
                  children: [
                    { value: { tag_label: "???", type: "dam" } },
                    { value: { tag_label: "???", type: "sire" } },
                  ],
                },
              ],
            };
          }

          if (this.parents) {
            temp_family_tree_data = {
              value: this.animal_prop,
              children: [
                {
                  value: {
                    tag_label: this.animal_prop.dam_tag_label,
                    tag_color: this.animal_prop.dam_tag_color,
                    image_timestamp: this.animal_prop.dam_image_timestamp,
                    image_id: this.animal_prop.dam_image_id,
                    id: this.animal_prop.dam_id,
                    customer_id: this.animal_prop.customer_id,
                    type: "dam",
                  },
                  children: [
                    {
                      value: {
                        tag_label: this.parents.dam.dam_tag_label
                          ? this.parents.dam.dam_tag_label
                          : "???",
                        tag_color: this.parents.dam.dam_tag_color,
                        image_timestamp: this.parents.dam.dam_image_timestamp,
                        image_id: this.parents.dam.dam_image_id,
                        id: this.parents.dam.dam_id,
                        customer_id: this.parents.dam.customer_id,
                        type: "dam",
                      },
                    },
                    {
                      value: {
                        tag_label: this.parents.dam.sire_tag_label
                          ? this.parents.dam.sire_tag_label
                          : "???",
                        tag_color: this.parents.dam.sire_tag_color,
                        image_timestamp: this.parents.dam.sire_image_timestamp,
                        image_id: this.parents.dam.sire_image_id,
                        id: this.parents.dam.sire_id,
                        customer_id: this.parents.dam.customer_id,
                        type: "sire",
                      },
                    },
                  ],
                },
                {
                  value: {
                    tag_label: this.animal_prop.sire_tag_label,
                    tag_color: this.animal_prop.sire_tag_color,
                    image_timestamp: this.animal_prop.sire_image_timestamp,
                    image_id: this.animal_prop.sire_image_id,
                    id: this.animal_prop.dam_id,
                    customer_id: this.animal_prop.customer_id,
                    type: "sire",
                  },
                  children: [
                    {
                      value: {
                        tag_label: this.parents.sire.dam_tag_label
                          ? this.parents.sire.dam_tag_label
                          : "???",
                        tag_color: this.parents.sire.dam_tag_color,
                        image_timestamp: this.parents.sire.dam_image_timestamp,
                        image_id: this.parents.sire.dam_image_id,
                        id: this.parents.sire.dam_id,
                        customer_id: this.parents.sire.customer_id,
                        type: "dam",
                      },
                    },
                    {
                      value: {
                        tag_label: this.parents.sire.sire_tag_label
                          ? this.parents.sire.sire_tag_label
                          : "???",
                        tag_color: this.parents.sire.sire_tag_color,
                        image_timestamp: this.parents.sire.sire_image_timestamp,
                        image_id: this.parents.sire.sire_image_id,
                        id: this.parents.sire.sire_id,
                        customer_id: this.parents.sire.customer_id,
                        type: "sire",
                      },
                    },
                  ],
                },
              ],
            };
          }
          this.family_tree = temp_family_tree_data;
        });
      this.$store.dispatch(
        "DDB_SELECTED_ANIMAL_OFFSPRING",
        this.animal_prop.id
      );
      this.animal = { ...this.animal_prop };
      if (this.unit_system_multiplier) {
        this.animal.measurement_weight_birth =
          this.animal.measurement_weight_birth *
          this.unit_system_multiplier.weight;
      }
      if (this.animal_prop.pedigree) {
        this.pedigree = { ...this.animal_prop.pedigree };
      }
    }

    this.selected_tab = parseInt(this.tab_index);
  },

  computed: {
    unit_system_multiplier() {
      if (this.$store.getters.getUserDDB.unit_system == "IMPERIAL") {
        return { length: 0.453592, weight: 2.54 };
      } else {
        return false;
      }
    },
    offspring_headers() {
      return [
        { text: "Tag", value: "tag_label" },
        { text: "RFID", value: "rfid_current" },
        { text: "Date of Birth", value: "dob" },
        { text: "Birth Type", value: "birth_ease" },
        { text: "Classification", value: "classification" },
      ];
    },
    offspring() {
      return this.$store.getters.getSelectedAnimalOffspring;
    },
    herds() {
      var herds = [];
      var herd_names = {};
      for (const i in this.$store.getters.getHerds) {
        herds.push({
          text: this.$store.getters.getHerds[i].name,
          value: this.$store.getters.getHerds[i].id,
        });
        herd_names[this.$store.getters.getHerds[i].id] =
          this.$store.getters.getHerds[i].name;
      }
      // herds.push({ name: "+", id: "+" });
      return [herds, herd_names];
    },
    enums() {
      var enums = {};
      enums.tag_color = this.$store.getters.getEnums.tag_color
        ? this.$store.getters.getEnums.tag_color
        : [];
      enums.production_type = this.$store.getters.getEnums.production_type
        ? this.$store.getters.getEnums.production_type
        : [];
      enums.sex_classification = this.$store.getters.getEnums.sex_classification
        ? this.$store.getters.getEnums.sex_classification
        : [];
      enums.animal_purpose = this.$store.getters.getEnums.animal_purpose
        ? this.$store.getters.getEnums.animal_purpose
        : [];
      enums.ownership_status = this.$store.getters.getEnums.ownership_status
        ? this.$store.getters.getEnums.ownership_status
        : [];
      enums.birth_ease = this.$store.getters.getEnums.birth_ease
        ? this.$store.getters.getEnums.birth_ease
        : [];
      enums.nurse_info = this.$store.getters.getEnums.nurse_info
        ? this.$store.getters.getEnums.nurse_info
        : [];
      enums.dam_status = this.$store.getters.getEnums.dam_status
        ? this.$store.getters.getEnums.dam_status
        : [];
      enums.animal_status = this.$store.getters.getEnums.animal_status
        ? this.$store.getters.getEnums.animal_status
        : [];
      return enums;
    },
    allowed_operations() {
      return this.$store.getters.getAllowedOperations;
    },

    parents() {
      return this.$store.getters.getSelectedAnimalParents;
    },
  },

  watch: {
    index() {
      this.$store
        .dispatch("DDB_SELECTED_ANIMAL_PARENTS", {
          dam_id: this.animal_prop.dam_id,
          sire_id: this.animal_prop.sire_id,
        })
        .then(() => {
          var temp_family_tree_data = {
            value: this.animal_prop,
            children: [
              {
                value: { tag_label: "???", type: "dam" },
                children: [
                  { value: { tag_label: "???", type: "dam" } },
                  { value: { tag_label: "???", type: "sire" } },
                ],
              },
              {
                value: { tag_label: "???", type: "sire" },
                children: [
                  { value: { tag_label: "???", type: "dam" } },
                  { value: { tag_label: "???", type: "sire" } },
                ],
              },
            ],
          };
          if (this.animal_prop.dam_id) {
            temp_family_tree_data = {
              value: this.animal_prop,
              children: [
                {
                  value: {
                    tag_label: this.animal_prop.dam_tag_label,
                    tag_color: this.animal_prop.dam_tag_color,
                    image_timestamp: this.animal_prop.dam_image_timestamp,
                    image_id: this.animal_prop.dam_image_id,
                    id: this.animal_prop.dam_id,
                    customer_id: this.animal_prop.customer_id,
                    type: "dam",
                  },
                  children: [
                    { value: { tag_label: "???", type: "dam" } },
                    { value: { tag_label: "???", type: "sire" } },
                  ],
                },
                {
                  value: {
                    tag_label: this.animal_prop.sire_tag_label,
                    tag_color: this.animal_prop.sire_tag_color,
                    image_timestamp: this.animal_prop.sire_image_timestamp,
                    image_id: this.animal_prop.sire_image_id,
                    id: this.animal_prop.dam_id,
                    customer_id: this.animal_prop.customer_id,
                    type: "sire",
                  },
                  children: [
                    { value: { tag_label: "???", type: "dam" } },
                    { value: { tag_label: "???", type: "sire" } },
                  ],
                },
              ],
            };
          }

          if (this.parents) {
            temp_family_tree_data = {
              value: this.animal_prop,
              children: [
                {
                  value: {
                    tag_label: this.animal_prop.dam_tag_label,
                    tag_color: this.animal_prop.dam_tag_color,
                    image_timestamp: this.animal_prop.dam_image_timestamp,
                    image_id: this.animal_prop.dam_image_id,
                    id: this.animal_prop.dam_id,
                    customer_id: this.animal_prop.customer_id,
                    type: "dam",
                  },
                  children: [
                    {
                      value: {
                        tag_label: this.parents.dam.dam_tag_label
                          ? this.parents.dam.dam_tag_label
                          : "???",
                        tag_color: this.parents.dam.dam_tag_color,
                        image_timestamp: this.parents.dam.dam_image_timestamp,
                        image_id: this.parents.dam.dam_image_id,
                        id: this.parents.dam.dam_id,
                        customer_id: this.parents.dam.customer_id,
                        type: "dam",
                      },
                    },
                    {
                      value: {
                        tag_label: this.parents.dam.sire_tag_label
                          ? this.parents.dam.sire_tag_label
                          : "???",
                        tag_color: this.parents.dam.sire_tag_color,
                        image_timestamp: this.parents.dam.sire_image_timestamp,
                        image_id: this.parents.dam.sire_image_id,
                        id: this.parents.dam.sire_id,
                        customer_id: this.parents.dam.customer_id,
                        type: "sire",
                      },
                    },
                  ],
                },
                {
                  value: {
                    tag_label: this.animal_prop.sire_tag_label,
                    tag_color: this.animal_prop.sire_tag_color,
                    image_timestamp: this.animal_prop.sire_image_timestamp,
                    image_id: this.animal_prop.sire_image_id,
                    id: this.animal_prop.dam_id,
                    customer_id: this.animal_prop.customer_id,
                    type: "sire",
                  },
                  children: [
                    {
                      value: {
                        tag_label: this.parents.sire.dam_tag_label
                          ? this.parents.sire.dam_tag_label
                          : "???",
                        tag_color: this.parents.sire.dam_tag_color,
                        image_timestamp: this.parents.sire.dam_image_timestamp,
                        image_id: this.parents.sire.dam_image_id,
                        id: this.parents.sire.dam_id,
                        customer_id: this.parents.sire.customer_id,
                        type: "dam",
                      },
                    },
                    {
                      value: {
                        tag_label: this.parents.sire.sire_tag_label
                          ? this.parents.sire.sire_tag_label
                          : "???",
                        tag_color: this.parents.sire.sire_tag_color,
                        image_timestamp: this.parents.sire.sire_image_timestamp,
                        image_id: this.parents.sire.sire_image_id,
                        id: this.parents.sire.sire_id,
                        customer_id: this.parents.sire.customer_id,
                        type: "sire",
                      },
                    },
                  ],
                },
              ],
            };
          }
          this.family_tree = temp_family_tree_data;
        });
      this.$store.dispatch(
        "DDB_SELECTED_ANIMAL_OFFSPRING",
        this.animal_prop.id
      );
      this.animal = { ...this.animal_prop };
      if (this.unit_system_multiplier) {
        this.animal.measurement_weight_birth =
          this.animal.measurement_weight_birth *
          this.unit_system_multiplier.weight;
      }
      if (this.animal_prop.pedigree) {
        this.pedigree = { ...this.animal_prop.pedigree };
      }
    },
  },
};
</script>
<style  lang="css" scoped>
#image_upload_span {
  top: 50%;
  transform: translateY(-54px);
  display: inline-block;
  position: relative;
  cursor: pointer;
}
#image_upload_text {
  display: inline-block;
  width: 100%;
  font-size: 14px;
}
#image_upload_icon {
  font-size: 50px;
}

#weight_card > div:nth-child(2) {
  height: 180px;
}

.table_tabs_ac {
  float: left;
  max-width: 520px !important;
  min-width: 220px !important;
}
</style>