<template>
                <v-card elevation="2" :height="height" style="border-radius: 10px 4px 4px 4px"
                  ><v-img
                    style="border-radius: 10px 4px 4px 4px"
                    height="100%"
                    aspect-ratio="1"
                    :src="
                        animal && animal.image_id
                          ? cropped_url()
                          : placeholder_img
                      "
                  ><v-overlay
                        opacity="0"
                        absolute
                        style="
                          justify-content: start;
                          align-items: start;
                          pointer-events: none;
                        "
                      >
                        <v-chip
                          style="
                            height: 24px;
                            margin-bottom: -4px;
                            margin-top: -5px;
                            padding-top: 2px;
                            padding-left: 5px;
                            padding-right: 5px;
                            border-radius: 9px;
                          "
                          v-if="animal.tag_label"
                          :color="
                            animal.tag_color && animal.tag_color != 'UNDEFINED'
                              ? 'oc_' +
                              animal.tag_color.toLowerCase() +
                                ' darken-1'
                              : 'grey darken-1'
                          "
                        >
                          <span
                            style="
                              color: white;
                              font-weight: 500;
                              font-size: 12px;
                            "
                            >{{ animal.tag_label }}</span
                          ></v-chip
                        >
                      </v-overlay></v-img>
         
 
                  </v-card>
  
</template>

<script>
import moment from "moment";
import placeholder_img_head from "@/assets/cover_brown.png";
import placeholder_img_body from "@/assets/cover_brown_body.png";
import placeholder_img_dam from "@/assets/cover_red.png";
import placeholder_img_sire from "@/assets/cover_green.png";


export default {
  name: "animalCard",
  components: {
  },
  props: ["animal", "type", "height", "ancestor"],
  data: () => ({
    placeholder_img_head: placeholder_img_head,
    placeholder_img_dam: placeholder_img_dam,
    placeholder_img_sire: placeholder_img_sire,
    placeholder_img_body: placeholder_img_body,
    dummy_nums: "?dummy=9999999",

  }),

  methods: {

    return_url() {
      this.dummy_nums =
        "?dummy=" + String(Math.floor(Math.random() * 9999999));
    },

    cropped_url() {
      let ymd = moment(this.animal.image_timestamp).format("YYYY/MM/DD")
      let total_date = moment(this.animal.image_timestamp).format(
        "YYYY-MM-DD_HH-mm-ss"
      );
      return `${process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE}${this.animal.customer_id}/animals/images/${ymd}/${this.animal.image_id}_${total_date}_${this.type}.jpg`;
    },
  },
  async mounted() {
    this.return_url();
  },

  computed: {

    placeholder_img() {
      if (!this.ancestor && this.type == "head") {
        return this.placeholder_img_head
      }
      if (!this.ancestor && this.type == "body") {
        return this.placeholder_img_body
      }
      if (this.ancestor && this.animal.type == "dam") {
        return this.placeholder_img_dam
      }
      if (this.ancestor && this.animal.type == "sire") {
        return this.placeholder_img_sire
      }
      return this.placeholder_img_head
    },


  },

};
</script>
<style  lang="css" scoped>
.tag_overlay {
  font-weight: 700;
  background-color: rgba(17, 17, 17, 0.6);
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 2px;
  border-radius: 5px;
}
.image_selector_icons_ac > button {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.image_selector_icons_ac {
  height: 14px;
  padding-bottom: 4px;
  display: inline-block;
}
.image_selector_ac {
  text-align: center;
  height: 24px;
  background-color: rgba(17, 17, 17, 0.6);
  padding-left: 4px;
  padding-right: 4px;
  margin-top: -6px;
  border-radius: 10px;
}

</style>